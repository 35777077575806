import React from 'react'

export default function Mislukt() {
  return (
    <div>
      <h1>Er is iets mis gegaan.</h1>
      <p>Helaas is uw bestelling niet geslaagd. Probeer het opnieuw.</p>
    </div>
  )
}
