import "./style/Main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactGA from "react-ga"

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Navigation from "./components/header/Navigation";
import Logo from "./components/header/Logo";

import { useMediaQuery } from "react-responsive";
import MobileBanner from "./components/homepage/MobileBanner";
import Timetable from "./components/timetable/Timetable";
import { Routes, Route, Link } from "react-router-dom";
import Films from "./components/body/Films";
import Film from "./components/body/Film";
import ArticleOver from "./components/body/ArticleOver";
import NotFound from "./components/body/NotFound";
import ArticleInfo from "./components/body/ArticleInfo";
import ArticleEvenementen from "./components/body/ArticleEvenementen";
import Programma from "./components/body/Programma";
import Programmas from "./components/body/Programmas";
import Article from "./components/body/Article";
import CustomPage from "./components/body/CustomPage";
import Intern from "./components/body/Intern";
import Bedankt from "./components/body/Bedankt";
import Mislukt from "./components/body/Mislukt";

const TRACKING_ID = "G-JC67FFLX90";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
	const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

	return (
		<div className='App'>
			{/* Header */}
			<Container className='headerCont' fluid>
				<Row className='headerRow'>
					<Col xs={9} lg={3} className='logoCol'>
						<Link to='/'>
							<Logo></Logo>
						</Link>
					</Col>
					<Col className='menuCol'>
						<Navigation></Navigation>
					</Col>
				</Row>
				{/* Desktop Content */}
				{!isMobile && (
					<Row className='contentRow bodyCont'>
						<Col xs={9} lg={3}>
							<Timetable></Timetable>
						</Col>
						<Col className="contentCol">
							{/* Desktop Body */}
							<Routes>
								<Route path='/' element={<Films />} />
								<Route path='/films/'>
									<Route index element={<Films />} />
									<Route path=':id' element={<Film />} />
								</Route>
								<Route path='/filmprogrammas/'>
									<Route index element={<Programmas />} />
									<Route path=':id' element={<Programma />} />
								</Route>
								<Route path='/over-kriterion/'>
									<Route
										path=':id'
										element={<ArticleOver />}
									/>
								</Route>
								<Route path='/informatie/'>
									<Route
										path=':id'
										element={<ArticleInfo />}
									/>
								</Route>
								<Route path='/evenementen/'>
									<Route
										path=':id'
										element={<ArticleEvenementen />}
									/>
								</Route>
								<Route path='/artikel/'>
									<Route path=':id' element={<Article />} />
								</Route>
								<Route path='/intern/' element={<Intern />} />
								<Route path='/bedankt/' element={<Bedankt />} />
								<Route path='/mislukt/' element={<Mislukt />} />
								<Route path=':id' element={<CustomPage />} />
								<Route path='*' element={<NotFound />} />
							</Routes>
						</Col>
					</Row>
				)}
			</Container>

			{/* Mobile Banner */}
			{isMobile && (
				<Routes>
					<Route path='/' element={<MobileBanner />} />
					<Route path='/films/' element={<MobileBanner />} />
					<Route path='/filmprogrammas/' element={<MobileBanner />} />
					<Route path='*' element={null} />
				</Routes>
			)}

			{/* Mobile Body */}
			<div className='bodyCont'>
				{isMobile && (
					<Routes>
						<Route
							path='/agenda/'
							element={<Timetable></Timetable>}
						/>
						<Route path='/' element={<Timetable></Timetable>} />
						<Route path='/films/'>
							<Route index element={<Films />} />
							<Route path=':id' element={<Film />} />
						</Route>
						<Route path='/filmprogrammas/'>
							<Route index element={<Programmas />} />
							<Route path=':id' element={<Programma />} />
						</Route>
						<Route path='/over-kriterion/'>
							<Route path=':id' element={<ArticleOver />} />
						</Route>
						<Route path='/informatie/'>
							<Route path=':id' element={<ArticleInfo />} />
						</Route>
						<Route path='/evenementen/'>
							<Route
								path=':id'
								element={<ArticleEvenementen />}
							/>
						</Route>
						<Route path='/artikel/'>
							<Route path=':id' element={<Article />} />
						</Route>
						<Route path='/intern/' element={<Intern />} />
						<Route path='/bedankt/' element={<Bedankt />} />
						<Route path='/mislukt/' element={<Mislukt />} />
						<Route path=':id' element={<CustomPage />} />
						<Route path='*' element={<NotFound />} />
					</Routes>
				)}
			</div>
		</div>
	);
}

export default App;
