import React, { useMemo } from "react";
import { useState } from "react";
import axios from "../../axios.js";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
// import cloudStorage from "./../../data/cloudStorageDownload.js"		
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";
// import Collapsible from "react-collapsible";

const days = [
	"Zondag",
	"Maandag",
	"Dinsdag",
	"Woensdag",
	"Donderdag",
	"Vrijdag",
	"Zaterdag",
];

const months = [
	"Januari",
	"Februari",
	"Maart",
	"April",
	"Mei",
	"Juni",
	"Juli",
	"Augustus",
	"September",
	"Oktober",
	"November",
	"December",
];

function addMinutes(date, minutes) {
	return new Date(date.getTime() + minutes * 60000);
}

function slugify(text) {
	text = text.replace(/'/g, '-');
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		// eslint-disable-next-line 
		.replace(/[^\w\-]+/g, '') // Remove all non-word chars
		// eslint-disable-next-line 
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
}

export default function Timetable() {
	const isMobile = useMediaQuery({ query: "(max-width: 991px)" });
	const [shows, setShows] = useState([]);
	const [expandedIndex, setExpandedIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false)

	function DropdownDates({ text, children, ...props }) {
		return (
			<div className={isOpen ? "dropdowndates active" : "dropdowndates"}>
				<ul
					className={
						isOpen
							? "dropdowndates-group active"
							: "dropdowndates-group"
					}
				>
					<li>
						<p
							className='datePickerButton'
							onClick={(e) => {
								setIsOpen(!isOpen);
							}}
						>
							{text}
						</p>
					</li>
					{isOpen && children}
				</ul>
			</div>
		);
	}

	const handleExpandItem = (index) => {
		if (index === expandedIndex) {
			setExpandedIndex(false);
		} else {
			setExpandedIndex(index);
		}
	};

	const dates = useMemo(
		() => [
			...new Set(
				shows.map((show) =>
					new Date(show.starts_at).toLocaleDateString("en-GB")
				)
			),
		],
		[shows]
	);

	React.useEffect(() => {
		axios.get("https://storage.googleapis.com/kritsite-buffer/shows.json").then((response) => {
			setShows(
				response.data.shows.filter(
					(show) =>
						new Date(show.starts_at) > addMinutes(new Date(), -10) // Check dat in future
				)
			);
		});
	}, []);

	

	return (
		<div className='timetable'>
			{!isMobile && <h2 className='desktop-titel'>Agenda</h2>}
			{isMobile && dates && (
				<div className='date-picker'>
					<p
						className='vandaag-button'
						onClick={() => handleExpandItem(0)}
					>
						Vandaag
					</p>
					<DropdownDates text='Alle dagen'>
						{dates && days && months &&
							dates.map((date, index) =>
								<li key={date}>
									<div
										className='dateButton'
										onClick={() => {
											handleExpandItem(index);
											setIsOpen(false);
										}
										}
									>
										<p className='left-text'>
											{isDay(stringToDate(date))}
										</p>
										<p className='right-text'>
											{getNumDay(date) +
												"/" +
												getNumMonth(date)}
										</p>
									</div>
								</li>
							)}
					</DropdownDates>
				</div>
			)}
			{shows && dates && days && months && (
				<Accordion className='timetable-accordion'>
					{dates.map((date, index) => (
						<AccordionItem
							key={date}
							dangerouslySetExpanded={expandedIndex === index}
						>
							{/* dangerouslySetExpanded={expanded === date} */}
							<AccordionItemHeading
								onClick={() =>
									handleExpandItem(index) &&
									setIsOpen(false)
								}
							>
								<AccordionItemButton className='timetable-accordion-title'>
									{(isToday(date)
										? "Vandaag"
										: isTomorrow(date)
											? "Morgen"
											: isDay(stringToDate(date))) +
										" " +
										getNumDay(date) +
										" " +
										months[getNumMonth(date) - 1]}
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								{shows
									.filter(
										(show) =>
											date ===
											addMinutes(new Date(   // add minutes for movies after midnight
												show.starts_at
											), -240).toLocaleDateString("en-GB")
									)
									.map((show) => (
										<div
											className='timetable-accordion-show'
											key={"tt" + show.id}
										>
											<p className="monoFont">{formatTime(show.starts_at)}</p>
											<Link
												to={
													"/films/" +
													slugify(show.name)
												}
												key={show.code}
											>
												{show.name}
											</Link>
											<a
												className='accordion-ticket-link'
												href={
													"https://tickets.kriterion.nl/kriterion/nl/flow_configs/webshop/steps/start/show/" +
													show.id
												}
											>
												Tickets
											</a>
										</div>
									))}
							</AccordionItemPanel>
						</AccordionItem>
					))}
				</Accordion>
			)}
		</div>
	);
}

// Helpers


function formatTime(timeString) {
	try {
		return new Date(timeString).toLocaleTimeString("en-GB", {
			hour: "2-digit",
			minute: "2-digit",
		});
	} catch (e) {
		return e.name === 'RangeError';
	}

}

function isDay(timeString) {
	var thisDate = new Date(timeString)
	// thisDate.setHours(thisDate.getHours() - 4)
	return days[thisDate.getDay()];
}

function isToday(dateString) {
	try {
		return dateString === new Date().toLocaleDateString("en-GB");
	} catch (e) {
		return e.name === 'RangeError';
	}
}

function isTomorrow(dateString) {
	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	try {
		return dateString === tomorrow.toLocaleDateString("en-GB");
	} catch (e) {
		return e.name === 'RangeError';
	}
}

function stringToDate(dateString) {
	return new Date(
		getNumYear(dateString),
		getNumMonth(dateString) - 1,
		getNumDay(dateString)
	);
}

function getNumDay(dateString) {
	return dateString.substring(0, 2);
}

function getNumMonth(dateString) {
	return dateString.substring(3, 5);
}

function getNumYear(dateString) {
	return dateString.substring(6);
}
