import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import CustomLink from "../CustomLink";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Navigation() {
	const [navbarOpen, setNavbarOpen] = useState(false);

	const handleToggle = () => {
		setNavbarOpen((prev) => !prev);
	};

	const resetNavbar = () => {
		setNavbarOpen(false);
	};

	const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

	// Mobile
	if (isMobile) {
		return (
			<nav className='navBar'>
				<button onClick={handleToggle}>
					<FaBars className='hamburger'></FaBars>
				</button>
				<ul className={`menuNav ${navbarOpen ? "showMenu" : ""}`}>
					<h2>BEZOEKERS INFORMATIE</h2>
					<CustomLink to='/informatie/openingstijden' onClick={resetNavbar}>Openingstijden</CustomLink>
					<CustomLink to='/informatie/contact' onClick={resetNavbar}>Contact</CustomLink>
					<CustomLink to='/informatie/cafe' onClick={resetNavbar}>Café</CustomLink>
					<CustomLink to='/informatie/overige-informatie' onClick={resetNavbar}>Overige Informatie</CustomLink>
					<h2>EVENEMENTEN</h2>
					<CustomLink to='/evenementen/exposities' onClick={resetNavbar}>Exposities</CustomLink>
					<CustomLink to='/evenementen/sessies-en-feestjes' onClick={resetNavbar}>Sessies & Feestjes </CustomLink>
					<CustomLink to='/evenementen/zaalhuur' onClick={resetNavbar}>Zaalhuur</CustomLink>
					<CustomLink to='/evenementen/kinderen' onClick={resetNavbar}>Kinderen</CustomLink>
					<h2>OVER KRITERION</h2>
					<CustomLink to='/over-kriterion/algemeen' onClick={resetNavbar}>
						Algemeen
					</CustomLink>
					<CustomLink to='/over-kriterion/kriterion-projecten' onClick={resetNavbar}>
						Kriterion projecten
					</CustomLink>
					<CustomLink to='/over-kriterion/werken-bij-kriterion' onClick={resetNavbar}>
						Werken bij Kriterion
					</CustomLink>
					<CustomLink to='/over-kriterion/de-geschiedenis' onClick={resetNavbar}>
						De geschiedenis
					</CustomLink>
				</ul>
			</nav>
		);
	}
	//Desktop
	return (
		<nav className='deskNavBar'>
			<Container fluid>
				<Row>
					<Col>
					<div className="deskMenuNavButton">
						<Link
							to='/filmprogrammas/'
						>
							FILMPROGRAMMA'S
						</Link>
						</div>
					</Col>
					<Col>
						<Dropdown text='OVERIGE EVENEMENTEN'>
							<CustomLink to='/evenementen/exposities'>
								Exposities
							</CustomLink>
							<CustomLink to='/evenementen/sessies-en-feestjes'>
								Sessies & Feestjes{" "}
							</CustomLink>
							<CustomLink to='/evenementen/zaalhuur'>
								Zaalhuur
							</CustomLink>
							<CustomLink to='/evenementen/kinderen'>
								Kinderen
							</CustomLink>
						</Dropdown>
					</Col>
					<Col>
						<Dropdown text='(BEZOEKERS)INFORMATIE'>
							<CustomLink to='/informatie/openingstijden'>
								Openingstijden
							</CustomLink>
							<CustomLink to='/informatie/contact'>
								Contact
							</CustomLink>
							<CustomLink to='/informatie/cafe'>Café</CustomLink>
							<CustomLink to='/informatie/overige-informatie'>
								Tarieven
							</CustomLink>
						</Dropdown>
					</Col>
					<Col>
						<Dropdown text='OVER KRITERION'>
							<CustomLink to='/over-kriterion/algemeen'>
								Algemeen
							</CustomLink>
							<CustomLink to='/over-kriterion/kriterion-projecten'>
								Kriterion projecten
							</CustomLink>
							<CustomLink to='/over-kriterion/werken-bij-kriterion'>
								Werken bij Kriterion
							</CustomLink>
							<CustomLink to='/over-kriterion/de-geschiedenis'>
								De geschiedenis
							</CustomLink>
						</Dropdown>
					</Col>
				</Row>
			</Container>
		</nav>
	);
}

function Dropdown({ text, children, ...props }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={isOpen ? "dropdown active" : "dropdown"}>
			<ul
				className={isOpen ? "dropdown-group active" : "dropdown-group"}
				onMouseEnter={(e) => {
					setIsOpen(true);
				}}
				onMouseLeave={(e) => {
					setIsOpen(false);
				}}
			>
				<li className='deskMenuNavButton'>{text}</li>
				{isOpen && children}
			</ul>
		</div>
	);
}
