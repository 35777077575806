// schemaHelpers.js
export function getTimetableSchema(movies) {
    return {
      "@context": "https://schema.org",
      "@graph": movies.map((movie) => ({
        "@type": "Event",
        "name": movie.title, // Movie title
        "startDate": movie.starts_at, // Start time of the show
        "endDate": movie.ends_at, // End time of the show
        "location": {
          "@type": "Place",
          "name": "Filmtheater Kriterion",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Roetersstraat 170",
            "addressLocality": "Amsterdam",
            "postalCode": "1018 WE",
            "addressCountry": "NL",
          },
        },
        "eventStatus": "https://schema.org/EventScheduled",
        "offers": {
          "@type": "Offer",
          "url": "https://tickets.kriterion.nl/kriterion/nl/flow_configs/webshop/steps/start/show/"+ movie.id , // Link to buy tickets for this specific show
          "availability": "https://schema.org/InStock",
        },
        "organizer": {
          "@type": "Organization",
          "name": "Filmtheater Kriterion",
          "url": "https://www.kriterion.nl",
        },
      })),
    };
  }
  
  
  