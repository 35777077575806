// import { useMediaQuery } from "react-responsive";
import React from "react";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

export default function Films() {
	//// For loading mechanic
	// const [allAssetsLoaded, setAllAssetsLoaded] = useState(false);
	const [films, setFilms] = useState([]);
	const [speciaal, setSpeciaal] = useState([]);
	const [verwachte, setVerwachte] = useState([]);
	const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

	React.useEffect(() => {
		axios
			.get(
				"https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/films?populate=*&pagination[page]=1&pagination[pageSize]=100&filters[$or][0][IsSpeciaal][$null]=true&filters[$or][1][IsSpeciaal][$eqi]=false&filters[release][$lte]=" + formatDate(new Date()) + "&sort=release:desc"
			)
			.then((response) => {
				setFilms(response.data.data);
			});

		axios
			.get(
				"https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/films?populate=*&pagination[page]=1&pagination[pageSize]=100&filters[IsSpeciaal][$eqi]=true&sort=release:asc"
			)
			.then((response) => {
				setSpeciaal(response.data.data);
			});

		axios
			.get(
				"https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/films?populate=*&pagination[page]=1&pagination[pageSize]=100&filters[$or][0][IsSpeciaal][$null]=true&filters[$or][1][IsSpeciaal][$eqi]=false&filters[release][$gt]=" + formatDate(new Date()) + "&sort=release:asc"
			)
			.then((response) => {
				setVerwachte(response.data.data);
			});

			//// For loading mechanic
		    // window.onload = () => {
			// 	setAllAssetsLoaded(true);
			//   };	

	}, []);

	return (
		<div className='body-container'>
			{isMobile && <h1 className={`titel`}>Nu te zien</h1>} 
			{/* {`titel transitioned-element ${allAssetsLoaded ? 'loaded' : ''}`} */}
			{isMobile && <div className="top-border">
				{films.map((film) => (
					<Link
						className={`films-container`}
						// {`films-container transitioned-element ${allAssetsLoaded ? 'loaded' : ''}`}
						key={'mobilereg-' +film.attributes.titel}
						to={"/films/" + film.attributes.productionid}
					>
						<img
							src={
								film.attributes.still.data.attributes.formats
									.medium.url
							}
							alt='Kriterion Logo'
						/>
						<div className='films-details'>
							<h3 className='films-details-title'>
								{film.attributes.titel}
							</h3>
							<p>
								{" "}
								{film.attributes.regie}{" "}
								{"(" + film.attributes.jaar + ")"}
							</p>
						</div>
					</Link>
				))}</div>}	
				
				{isMobile && <h1 className="titel">Speciaal</h1>}
				{isMobile && <div className="top-border">
					{speciaal.map((film) => (
						<Link
							className='films-container'
							key={'mobilespec-'+film.attributes.titel}
							to={"/films/" + film.attributes.productionid}
						>
							<img
								src={
									film.attributes.still.data.attributes.formats
										.medium.url
								}
								alt='Kriterion Logo'
							/>
							<div className='films-details'>
								<h3 className='films-details-title'>
									{film.attributes.titel}
								</h3>
								<p>
									{" "}
									{film.attributes.regie}{" "}
									{"(" + film.attributes.jaar + ")"}
								</p>
							</div>
						</Link>
					))}</div>}
				 
				{isMobile && <h1 className="titel">Verwacht</h1>}
				{isMobile &&  ( <div className="top-border">
				{verwachte.map((verwacht) => (
					<Link
						className='films-container'
						key={'mobileverw-'+verwacht.attributes.titel}
						to={"/films/" + verwacht.attributes.productionid}
					>
						<img
							src={
								verwacht.attributes.still.data.attributes.formats
									.medium.url
							}
							alt='Kriterion Logo'
						/>
						<div className='films-details'>
							<h3 className='films-details-title'>
								{verwacht.attributes.titel}
							</h3>
							<p>
								{" "}
								{verwacht.attributes.regie}{" "}
								{"(" + verwacht.attributes.jaar + ")"}
							</p>
						</div>
					</Link>
				))} </div>)}

			{!isMobile && (
				<div className="desktop-films-titel-wrapper">
					<h2 className='desktop-titel'>Nu te zien</h2>
					<div className='desktop-films-wrapper'>
						{films.map((film) => (
							<Link
								className='films-container'
								key={'regulier-'+film.attributes.titel}
								to={"/films/" + film.attributes.productionid}
								style={{
									backgroundImage:
										"url(" +
										film.attributes.still.data.attributes
											.formats.medium.url +
										")",
								}}
							>
								<div className='films-details'>
									<h3 className='films-details-title'>
										{film.attributes.titel}
									</h3>
									<p>
										{" "}
										{film.attributes.regie}{" "}
										{"(" + film.attributes.jaar + ")"}
									</p>
								</div>
							</Link>
						))}
					</div>

					<h2 className='desktop-titel'>Speciaal</h2>
					<div className='desktop-films-wrapper'>
						{speciaal.map((film) => (
							<Link
								className='films-container'
								key={'spec-'+film.attributes.titel}
								to={"/films/" + film.attributes.productionid}
								style={{
									backgroundImage:
										"url(" +
										film.attributes.still.data.attributes
											.formats.medium.url +
										")",
								}}
							>
								<div className='films-details'>
									<h3 className='films-details-title'>
										{film.attributes.titel}
									</h3>
									<p>
										{" "}
										{film.attributes.regie}{" "}
										{"(" + film.attributes.jaar + ")"}
									</p>
								</div>
							</Link>
						))}
					</div>


					<h2 className='desktop-titel'>Verwacht</h2>
					<div className='desktop-films-wrapper'>
						{verwachte.map((verwacht) => (
							<Link
								className='films-container'
								key={'verw-'+verwacht.attributes.titel}
								to={"/films/" + verwacht.attributes.productionid}
								style={{
									backgroundImage:
										"url(" +
										verwacht.attributes.still.data.attributes
											.formats.medium.url +
										")",
								}}
							>
								<div className='films-details'>
									<h3 className='films-details-title'>
										{verwacht.attributes.titel}
									</h3>
									<p>
										{" "}
										{verwacht.attributes.regie}{" "} <br />
										{"(" + verwacht.attributes.release + ")"}
									</p>
								</div>
							</Link>
						))}
					</div>

				</div>
			)}
		</div>
	);
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
