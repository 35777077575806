import React from 'react'
import axios from "axios";
import { useState } from "react";
import Cookies from 'js-cookie';


export default function Intern() {
  // const [bestanden, setBestanden] = useState([]);

  const [roosters, setRoosters] = useState([]);
  const [programmas, setProgrammas] = useState([]);
  const [handleidingen, setHandleidingen] = useState([]);
  const [notulen, setNotulen] = useState([]);
  const [boekjes, setBoekjes] = useState([]);


  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  React.useEffect(() => {
    axios
      .get(
        "https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/intern-bestanden?populate=deep"
      )
      .then((response) => {
        // setBestanden(response.data.data);

        setRoosters(response.data.data.attributes.roosters);
        setProgrammas(response.data.data.attributes.programmas);
        setHandleidingen(response.data.data.attributes.handleidingen);
        setNotulen(response.data.data.attributes.notulen);
        setBoekjes(response.data.data.attributes.boekjes);
      });

    // Check if the user is already authenticated using a cookie
    const isAuthenticated = Cookies.get('authenticated') === 'true';
    setAuthenticated(isAuthenticated);
  }, []);

  const handlePasswordChange = (e) => {
    // No need to sanitize user input
    setPassword(e.target.value);
  };

  const handleAuthenticate = () => {
    // Simulate server-side authentication
    if (authenticateUserOnServer(password)) {
      // Set a cookie to remember authentication
      Cookies.set('authenticated', 'true', { expires: 1 }); // Expires in 1 day
      setAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const handleLogout = () => {
    // Clear the authentication cookie and reset the state
    Cookies.remove('authenticated');
    setAuthenticated(false);
  };

  // Simulated server-side authentication function
  const authenticateUserOnServer = (inputPassword) => {
    const storedPassword = 'janpassword'; // Replace with your stored plain text password

    // Compare the input password with the stored plain text password
    return inputPassword === storedPassword;
  };


  return (
    <div>
      {authenticated ? (
        // Content to display when authenticated
        <div className='intern'>
          <button className="logout-button" onClick={handleLogout}>Logout</button>
          <iframe src="https://calendar.google.com/calendar/embed?src=qhuo9j77i8jj16ka33slcehvbk%40group.calendar.google.com&ctz=Europe%2FAmsterdam&src=zaalhuur%40gmail.com&ctz=Europe%2FAmsterdam&src=ov76p845d9ambetil62cr6nq50%40group.calendar.google.com&ctz=Europe%2FAmsterdam" className="calendar"  title="calendar"></iframe>
          <section className='intern-files'>
            <section>
              <h1>Roosters</h1>
              {
                roosters && roosters.map((rooster) =>
                  <a href={rooster.bestand.data.attributes.url} key={rooster.bestand.data.attributes.name}>{rooster.bestand.data.attributes.name}</a>
                )
              }
            </section>
            <section>
              <h1>Programma per week</h1>
              {
                programmas && programmas.map((programma) =>
                  <a href={programma.bestand.data.attributes.url} key={programma.bestand.data.attributes.name}>{programma.bestand.data.attributes.name}</a>
                )
              }
            </section>
            <section>
              <h1>Handleidingen</h1>
              {
                handleidingen && handleidingen.map((handleiding) =>
                  <a href={handleiding.bestand.data.attributes.url} key={handleiding.bestand.data.attributes.name}>{handleiding.bestand.data.attributes.name}</a>
                )
              }
            </section>
            <section>
              <h1>Notulen</h1>
              {
                notulen && notulen.map((notule) =>
                  <a href={notule.bestand.data.attributes.url} key={notule.bestand.data.attributes.name}>{notule.bestand.data.attributes.name}</a>
                )
              }
            </section>
            <section>          
              <h1>Boekjes</h1>
              {
                boekjes && boekjes.map((boekje) =>
                  <a href={boekje.bestand.data.attributes.url} key={boekje.bestand.data.attributes.name}>{boekje.bestand.data.attributes.name}</a>
                )
              }
            </section>
          </section>


        </div>
      ) : (
        // Password input form
        <div>
          <h1>Password Required</h1>
          <input
            type="password"
            placeholder="Enter the password"
            value={password}
            onChange={handlePasswordChange}
            className='password-prompt'
          />
          <button onClick={handleAuthenticate} className='password-prompt'>Authenticate</button>
        </div>
      )}
    </div>
  )
}
