import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

export default function Article() {
	const { id } = useParams();
	
	const [link, setLink] = useState(false);
	const [error, setError] = useState("");

	React.useEffect(() => {
		async function fetchData() {
			axios
				.get(
					`https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/custom-urls/${id}`
				)
				.then((res) => {
					setError("");
					setLink(res.data.data);
				})
				.catch((e) => {
					const { status } = e.response;
					if (status === 404) {
						setLink();
						setError("Page not found");
						return;
					}
					setError("Something went wrong, try again later");
				});
		}
		fetchData();
	}, [id]);

	// article && console.log(JSON.parse(article.attributes.Inhoud[1].Afbeelding));

	return (
		<React.Fragment>
			{link && (
				getContent(link)
			)}
			{error && <p className='error'>{error}</p>}
		</React.Fragment>
	);
}

function getContent(link){
    let contenttype = link.attributes.pagina[0].__component
    if (contenttype === "link.film"){
		console.log()
        return <Navigate to={"/films/" + link.attributes.pagina[0].film.data.attributes.productionid} />
    } else if (contenttype === "link.programma"){
        return <Navigate to={"/filmprogrammas/" + link.attributes.pagina[0].programma.data.attributes.slug} />
    } else if (contenttype === "link.artikel"){
		return <Navigate to={"/artikel/" + link.attributes.pagina[0].artikel.data.attributes.Slug} />

    }
}