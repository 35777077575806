import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import FilmTimes from "./FilmTimes";
import { useMediaQuery } from "react-responsive";

export default function Film() {
	const { id } = useParams();
	const [film, setFilm] = useState(false);
	const [error, setError] = useState("");
	const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

	React.useEffect(() => {
		async function fetchData() {
			axios
				.get(
					`https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/films/${id}?populate=*`
				)
				.then((res) => {
					setError("");
					setFilm(res.data.data);
				})
				.catch((e) => {
					const { status } = e.response;
					if (status === 404) {
						setFilm(false);
						setError("Title not found");
						return;
					}
					setError("Something went wrong, try again later");
				});
		}
		fetchData();
		window.scrollTo(0, 0);
	}, [id]);

	return (
		<React.Fragment>
			<div className="body-container">
				{film && (
					<div className="film-content">
						<img
							className='banner-image'
							src={
								film.attributes.still.data.attributes.formats.medium
									.url
							}
							alt={"Filmstill van " + film.attributes.titel}
						></img>
						<div className='film-details'>
							<h2 className='film-titel' key={film.attributes.titel}>
								{" "}
								{film.attributes.titel}
							</h2>
							<p className='film-subtekst'>
								{film.attributes.regie} - {film.attributes.jaar} -{" "}
								{film.attributes.speelduur} 
								{isMobile && <br />}
								{!isMobile && " - "}
								{film.attributes.gesproken} -{" "}
								{film.attributes.ondertitels}
							</p>
						</div>
						<FilmTimes slug={id}></FilmTimes>
						{JSON.parse(film.attributes.beschrijving).blocks.map(
							(block) => {
								return (
									<p
										className='tekst'
										key={block.id}
										dangerouslySetInnerHTML={{
											__html: block.data.text,
										}}
									/>
								);
							}
						)}
					</div>
				)}
				{error && <p className='error'>{error}</p>}
			</div>
		</React.Fragment>
	);
}

// function isOndertitel(taal) {
// 	if (taal === "Nederlands") {
// 		return "NL";
// 	} else if (!taal || taal === "Geen"){
// 		return "Geen";
// 	} else {
// 		return taal + "e";
// 	}
// }

// function isGesproken(taal) {
// 	if (taal === "Nederlands") {
// 		return "NL";
// 	} else if (!taal){
// 		return "Niet";
// 	} else {
// 		return taal;
// 	}
// }
