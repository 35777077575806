import React from 'react';
import KriterionLogo from './kriterion-logo.svg';

function Logo() {
    return (
        <div className='mainLogo'>
            <img src={KriterionLogo} alt='Kriterion Logo'/>
        </div>      
    );
}

export default Logo;