import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

export default function ArticleInfo() {
	const { id } = useParams();
	const [article, setArticle] = useState(false);
	const [error, setError] = useState("");

	React.useEffect(() => {
		async function fetchData() {
			axios
				.get(
					`https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/links-informatie/${id}`
					// links-over-kriterions, links-informatie, links-evenementen
				)
				.then((res) => {
					setError("");
					setArticle(res.data.data);
				})
				.catch((e) => {
					const { status } = e.response;
					if (status === 404) {
						setArticle(false);
						setError("Title not found");
						return;
					}
					setError("Something went wrong, try again later");
				});
		}
		fetchData();
	}, [id]);

	// article && console.log(JSON.parse(article.attributes.Inhoud[1].Afbeelding));

	return (
		<React.Fragment>
			<div className="body-container">
			{article && (
				<div className="article-content">
					<h1 className="titel">{article.attributes.MenuTitel}</h1>
					{// eslint-disable-next-line
                    article.attributes.Inhoud.map((comp) => {
						if (comp.__component === "artikel.tekst") {
							return JSON.parse(comp.Tekst).blocks.map((block) => {
								return (
									<p
										className="tekst"
										key={block.id}
										dangerouslySetInnerHTML={{
											__html: block.data.text,
										}}
									/>
								);
							});
						} else if (comp.__component === "artikel.afbeelding") {
                            return (<img className="artikel-image" key={comp.id} src={comp.Afbeelding.data.attributes.url} alt="random"></img>)
                        }
					})}
				</div>
			)}
			{error && <p className='error'>{error}</p>}
			</div>
		</React.Fragment>
	);
}
