import React from 'react'

export default function Bedankt() {
  return (
    <div>
      <h1>Bedankt!</h1>
      <p>Uw tickets zijn verzonden naar uw mail. Tot snel!</p>
    </div>
  )
}
