import React, { useMemo } from "react";
import axios from "../../axios.js";
import { useState } from "react";
import Masonry from "react-masonry-css";
import { useMediaQuery } from "react-responsive";

export default function FilmTimes(props) {
	const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
	const [shows, setShows] = useState([]);
	const [films, setFilms] = useState([]);
	const masonryRows = isMobile ? 3 : 6



	const filmdates = useMemo(
		() => [
			...new Set(
				getShowsById(films[props.slug], shows).map((show) =>
					addMinutes(new Date(show.starts_at), -240).toLocaleDateString("en-GB")
				)
			),
		],
		[shows, films, props.slug]
	);

	React.useEffect(() => {
		axios.get("https://storage.googleapis.com/kritsite-buffer/shows.json").then((response) => {
			setShows(response.data.shows.filter(
				(show) =>
					new Date(show.starts_at) > addMinutes(new Date(), 20) // Check dat in future
			));
		});

		axios.get("https://storage.googleapis.com/kritsite-buffer/films.json").then((response) => {
			setFilms(response.data);
		});
	}, []);

	return (
		<React.Fragment>
			{films && shows && (<Masonry 
			breakpointCols={masonryRows}
			className="filmtimes-container"
			columnClassName="filmtimes-column">
				{filmdates.map((date) => (
					<div className="filmtimes-day" key={date}>
						<p className="filmtimes-title sportingBold">
							{isDay(stringToDate(date))} {getNumDay(date)}-
							{getNumMonth(date)}
						</p>

						{getShowsById(films[props.slug], shows)
							.filter(
								(showx) =>
									date ===
									addMinutes(new Date(showx.starts_at), -240).toLocaleDateString("en-GB")
							)
							.map((show) => (
								<a href={"https://tickets.kriterion.nl/kriterion/nl/flow_configs/webshop/steps/start/show/" + show.id} key={show.id}><p className="filmtimes-slot monoFontBold">{formatTime(show.starts_at)}</p></a>
							))}
					</div>
				))}
			</Masonry>)}
		</React.Fragment>
	);
}

const days = ["ZO", "MA", "DI", "WO", "DO", "VR", "ZA"];

function getShowsById(id, shows) {
	return shows.filter((show) => show.production_id === id);
}

function addMinutes(date, minutes) {
	return new Date(date.getTime() + minutes * 60000);
}

function stringToDate(dateString) {
	return new Date(
		getNumYear(dateString),
		getNumMonth(dateString) - 1,
		getNumDay(dateString)
	);
}

function formatTime(timeString) {
	try {
		return new Date(timeString).toLocaleTimeString("en-GB", {
			hour: "2-digit",
			minute: "2-digit",
		});
	} catch (e) {
		return e.name === 'RangeError';
	}

}

function isDay(timeString) {
	var thisDate = new Date(timeString)
	return days[thisDate.getDay()];
}

function getNumDay(dateString) {
	return dateString.substring(0, 2);
}

function getNumMonth(dateString) {
	return dateString.substring(3, 5);
}

function getNumYear(dateString) {
	return dateString.substring(6);
}
