import React from 'react';
import axios from "axios";
import { useState } from "react";
import { useMatch, useResolvedPath } from "react-router-dom"
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";


export default function MobileBanner() {
    const { id } = useParams();
	const [bannerFilm, setBannerFilm] = useState();
    // const [bannerActive, setBannerActive] = useState(id);

    React.useEffect(() => {
		axios
			.get(
				"https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/films?populate=*&pagination[page]=1&pagination[pageSize]=100&filters[release][$lte]=" + formatDate(new Date()) + "&sort=release:desc"
			)
			.then((response) => {
				setBannerFilm(getRandomItem(response.data.data)); // doe iets hiermee zodat enkel een random film return
			});

	}, []);

    const resolvedPath = useResolvedPath(id)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <div className='mobileBanner' style={ bannerFilm && {backgroundImage: `url(${bannerFilm.attributes.still.data.attributes.formats
            .medium.url})`}}>
            <ul>
                {/* for selected, double border. add routing and active property */}
                <li className={isActive ? "double-border" : ""}><Link className='banner-link' to="/">Agenda</Link>
          </li>
                <li className={isActive ? "double-border" : ""}><Link className='banner-link' to="/filmprogrammas">Programmas</Link></li>
                <li className={isActive ? "double-border" : ""}><Link className='banner-link' to="/films">Alle films</Link></li>
            </ul>
        </div>      
    );
}

function getRandomItem(list){
    const randomItem = Math.floor(Math.random() * Math.floor(list.length));
    return list[randomItem]; // Pak random int van 0 naar lengte list (excl). 
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
